import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHeader from '../components/page-header'
import Button from '../components/button'
import Link from '../components/link'
import { Row, Col, Container } from '../components/grid'
import SubHeader from '../components/sub-header'

const BlankPage = () => (
  <>
    <SEO title="Home" />
    <Layout>
      <PageHeader>Blank Page</PageHeader>
      <Container>
        <SubHeader textAlign="center">Sub header</SubHeader>
        <Row height="166px" alignItems="center">
          <Col col={1/2}>
            Buttons
            <Button>Test</Button>
          </Col>
          <Col col={1/2}>
            Links
            <Link>Link</Link>
          </Col>
        </Row>
      </Container>
    </Layout>
  </>
)

export default BlankPage
